.notifications-container {
  position: static;
}
.notifications-list {
  width: 0vw;
  right: 0;
  top: 0;
  height: 100vh;
  transition: .3s;
  box-shadow: 9px 9px 9px rgba(0, 0, 0, 0.15);
}
.notifications-list.show {
  width: 100vw;
  transition: .3s;
  margin-top: 1.685rem;
}
.new-notifications::after {
  content: '';
  width: 0.5rem;
  height: 0.5rem;
  background-color: #FD7779;
  border-radius: 50%;
  position: absolute;
  bottom: 80%;
  left: 100%;
}
.notifications-placeholder-background {
  width: 6.25rem;
  height: 6.25rem;
}
.close-notifications-text {
  font-size: 0.688rem;
}
.notifications-title {
  border-bottom: 1px solid #dee2e6;
}
.notifications-item {
  border-bottom: 1px solid #C9C9C9;
}
.readed-notification {
  background-color: #EFF0F4;
}

.readed-notification:hover {
  background-color: #e4e4e4;
}

.readed-notification span, .readed-notification span:hover {
  color: #828282;
}
@media screen and (min-width: 992px) {
  .notifications-container {
    position: relative;
  }
  .notifications-list {
    width: 23.438rem;
    height: auto;
    max-height: 26.563rem;
    top: auto;
    right: calc(100% - 13.75rem);
    margin-top: 1.9rem;
  }
  .notifications-list.show {
    width: 23.438rem;
  }
}