#root {
  background-color: #f8f9fa !important;
  min-height: 100vh;
}

body {
  font-family: unset !important;
}

.switch-reset {
  padding-left: 0;
}

.custom-control {
  padding-left: 0;
}

.badge-filter {
  cursor: pointer;
}

.container-max {
  max-width: 1400px;
  margin: auto;
}

.main-container {
  min-height: 80vh;
}

.btn-primary {
  background-color: #52c0f7 !important;
  border-color: #52c0f7 !important;
}

.react-datepicker-wrapper {
  width: 100%;
}
.show {
  transition: opacity 0.5s !important;
}
.show {
  opacity: 1;
  transform: translateX(0px);
  transition: opacity 1s, transform 1s;
}

.non-selectable {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.font-weight-500 {
  font-weight: 500;
}

.dark .ant-table .ant-table-cell,
.dark .ant-table-cell-row-hover,
.dark .css-1rhbuit-multiValue,
.dark .css-12jo7m5 {
  background-color: rgb(24, 26, 27) !important;
}

.dark .ant-table-cell,
.dark .ant-input,
.dark .ant-input-textarea-show-count::after,
.dark .anticon,
.dark .css-1rhbuit-multiValue,
.dark .css-12jo7m5,
.dark .rbc-btn-group > button,
.dark .rbc-label,
.dark .rbc-toolbar-label,
.dark .rbc-header,
.dark .rbc-agenda-empty,
.dark .ant-progress-text,
.dark .ant-btn > span,
.dark .ant-modal-confirm-title {
  color: white !important;
}

.dark .rbc-active,
.dark .ant-modal-header,
.dark .ant-modal-body,
.dark .ant-modal-footer,
.dark .ant-modal-title,
.dark .ant-btn:hover,
.dark .ant-btn-icon-only > span,
.dark .ant-btn-icon-only:hover,
.dark .ant-tooltip-open,
.dark .ant-notification-notice,
.dark .ant-notification-notice-closable,
.dark .ant-notification-notice-message {
  color: white;
  background-color: rgb(27, 30, 31) !important;
}

.ant-btn,
.ant.btn-primary {
  color: rgb(27, 30, 31);
  font-size: 0.75rem;
}

.ant-modal-close {
  top: 0.935rem;
}

.margin-fix-input {
  margin-top: 1.375rem;
}

.margin-fix-input-institute {
  margin-top: 1rem !important;
}

.dark .rbc-today {
  background-color: grey !important;
}

select {
  opacity: 1;
}

input {
  font-weight: 500;
}

.input-formation-career {
  margin-top: 1.75rem;
}

.custom-select,
.custom-select-input,
.react-datepicker__month-text,
.react-datepicker-year-header,
.css-ckaqek-placeholder {
  font-family: 'Montserrat';
}

.react-datepicker__month-text {
  font-size: 0.685rem;
}

.custom-margin-picker {
  margin-top: -0.72rem !important;
}

.container-danger-text {
  top: 2.4rem;
}

.error-container-height {
  height: calc(100vh - 80px);
}
