.challenges-cards {
  background: #fff;
  border-radius: 8px;
  display: inline-block;
  position: relative;
  border: 1px solid #222222;
}

.screening-invitation-tooltip div {
  background-color: white;
  font-family: 'Montserrat';
  color: #777777;
}

.screening-invitation-tooltip.tooltip.show {
  opacity: 1;
}

.screening-invitation-tooltip .arrow::before {
  border-top-color: white !important;
}

/* .card-3 {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
} */

.text-gray-challenge {
  color: #828282;
}

.button-apply-challenge {
  background: #58c1f5;
  border-radius: 1rem;
  border: none;
  padding: 0.315rem 0rem;
}

.button-apply-challenge-text {
  font-size: 0.875rem;
  line-height: 1rem;
  margin: 0rem 0.25rem;
  text-decoration-line: none;
}

.ticket-challenge-text {
  font-size: 0.815rem;
  line-height: 0.935rem;
  letter-spacing: 0.07em;
  padding: 0.175rem;
  border-radius: 0.125rem;
  color: #176b71;
  background: #d0f0ff;
}

.ticket-padding {
  font-size: 0.815rem;
  line-height: 0.935rem;
  letter-spacing: 0.07em;
  padding: 0.175rem;
  border-radius: 0.125rem;
  display: flex;
  align-items: center;
}

.title-challenge-1 {
  font-size: 1.375rem;
  line-height: 1.685rem;
}

.title-challenge-2 {
  font-size: 2.25rem;
  line-height: 2.375rem;
}

.title-challenge-3 {
  font-size: 1.25rem;
  line-height: 1.875rem;
}

.ticket-technology-frontend {
  color: #403692;
  background: #eae7fe;
}

.ticket-technology-fullstack {
  color: #fd6466;
  background: #ffe2e3;
}

.text-hover:hover {
  color: #403692 !important;
  text-decoration: underline !important;
}