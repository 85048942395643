.bg-link {
  background-color: #eaeaea;
  white-space: pre-line;
}

.link-container {
  max-width: 13.125rem;
}

.hover-maya-blue:hover {
  color: #58c1f5;
}

@media screen and (min-width: 576px) {
  .link-container {
    max-width: none;
  }
}