.hidden-element {
  @media screen and (max-width: 843px) {
    visibility: hidden !important;
  }
}

.table-header {
  padding-left: 1.063rem;
  padding-right: 1.063rem;

  @media screen and (max-width: 843px) {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
    justify-content: space-between !important;
  }
}
.close-btn-hidden {
  width: 95px;
  @media screen and (max-width: 843px) {
    width: 6.25rem;
  }
}
.spacing {
  display: none;
  @media screen and (max-width: 843px) {
    width: 6.25rem;
    display: block;
    visibility: hidden;
  }
}

.header-spacing {
  display: none;
  @media screen and (max-width: 843px) {
    width: 6.25rem;
    display: block;
    visibility: hidden;
  }
}

.content-score {
  text-align: left;
  @media screen and (max-width: 843px) {
    text-align: center;
  }
}

.evaluation-result-width {
  width: 25%;
  @media screen and (max-width: 843px) {
    width: 53%;
  }
}
