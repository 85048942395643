.hover-overlay {
  border-left: 5px solid #f8f7f5;
}

.hover-overlay:hover {
  background: #f8f7f5;
  border-left: 5px solid #52c0f7;
}

.mentor-slack-icon {
  width: 1.5rem;
  height: 1.5rem;
}

.slack-icon-small {
  width: 1rem;
  height: 1rem;
}

.user-heading-round {
  border: 10px solid rgba(255, 255, 255, 0.3);
}
.user-heading-img {
  width: 112px;
  height: 112px;
}

.round-img {
  width: 100px;
  height: 100px;
  border-radius: 150px;
}

/* CARD */

.img1 {
  height: 150px;
  background-color: rgb(130, 220, 223);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  max-width: 100%;
}
.img2 img {
  z-index: 1;
  width: 140px;
  height: 140px;
  border: 7px solid royalblue;
  margin-top: -80px;
}

.main-text {
  padding: 15px 0;
}

.main-text h2 {
  font-size: 20px;
  margin: 0 0 20px;
}

.main-text p {
  font-size: 16px;
  padding: 0 35px;
}

/* CARDS DASHBOARD */

.card-counter {
  box-shadow: 2px 2px 10px #dadada;
  margin: 5px;
  padding: 20px 10px;
  margin-left: 0;
  margin-right: 0;
  background-color: #fff;
  height: 100px;
  transition: 0.3s linear all;
}

.card-counter:hover {
  box-shadow: 4px 4px 20px #dadada;
  transition: 0.3s linear all;
}

.card-counter.primary {
  background-color: #007bff;
  color: #fff;
}

.card-counter.danger {
  background-color: #ef5350;
  color: #fff;
}

.card-counter.success {
  background-color: #66bb6a;
  color: #fff;
}

.card-counter.info {
  background-color: #26c6da;
  color: #fff;
}

.card-counter i {
  font-size: 5rem;
  opacity: 0.2;
}

.card-counter .count-numbers {
  position: absolute;
  right: 35px;
  top: 20px;
  font-size: 1.625rem;
  display: block;
}

.card-counter .count-name {
  position: absolute;
  right: 35px;
  top: 65px;
  font-style: italic;
  text-transform: capitalize;
  opacity: 0.5;
  display: block;
  font-size: 18px;
}

.Demo__some-network__share-button {
  padding: 0 10px !important;
}

.unselected-option {
  color: rgba(255, 255, 255, .5);
}

.hero-container {
  height: 13rem;
}

.mentor-container {
  max-width: none;
}

.team-container {
  max-width: none;
}

@media screen and (min-width: 992px) {
  .mentor-container {
    max-width: 21rem;
  }
  .team-container {
    max-width: 56rem;
  }
  .font-team {
    font-size: 1.5rem
  }
}