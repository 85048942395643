#wrapper {
  overflow-x: d-none;
}

#sidebar-wrapper {
  min-height: 100vh;
  margin-left: -15rem;
  transition: margin 0.25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#sidebar-wrapper .list-group {
  width: 15rem;
}

#page-content-wrapper {
  min-width: 100vw;
}

#wrapper.toggled #sidebar-wrapper {
  margin-left: 0;
}

@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }

  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }

  #wrapper.toggled #sidebar-wrapper {
    margin-left: -15rem;
  }
}

a[data-toggle='collapse'] {
  position: relative;
}

.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

#components-layout-demo-custom-trigger .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
}

.sidebar-menu-fixed {
  height: 100vh;
  position: sticky;
  top: 0;
  scrollbar-color: #7e7e7e !important;
  scrollbar-width: thin !important;
}
.sidebar-menu-container::-webkit-scrollbar {
  -webkit-appearance: none;
}

.sidebar-menu-container::-webkit-scrollbar:vertical {
  width: 5px;
}

.sidebar-menu-container::-webkit-scrollbar-button:increment,
.sidebar-menu-container::-webkit-scrollbar-button {
  display: none;
}

.sidebar-menu-container::-webkit-scrollbar:horizontal {
  height: 5px;
}

.sidebar-menu-container::-webkit-scrollbar-thumb {
  background-color: #7e7e7e;
  border-radius: 20px;
}

.sidebar-menu-container::-webkit-scrollbar-track {
  border-radius: 10px;
}

.sidebar-menu-container::-moz-scrollbar {
  -moz-appearance: none;
}

.sidebar-menu-container::-moz-scrollbar:vertical {
  width: 5px;
}

.sidebar-menu-container::-moz-scrollbar-button:increment,
.sidebar-menu-container::-moz-scrollbar-button {
  display: none;
}

.sidebar-menu-container::-moz-scrollbar:horizontal {
  height: 5px;
}

.sidebar-menu-container::-moz-scrollbar-thumb {
  background-color: #7e7e7e;
  border-radius: 20px;
}

.sidebar-menu-container::-moz-scrollbar-track {
  border-radius: 10px;
}

.sidebar-menu-container {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow: d-none;
  overflow-y: auto;
}

.site-layout-header-fixed {
  top: 0;
  z-index: 999;
}

.site-layout-content {
  min-height: 120vh !important;
  background-color: #f2f2f2 !important;
}

.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  margin: 1rem;
}

.site-layout .site-layout-background {
  background: #fff;

  .btn-outline-primary:hover {
    background-color: #52c0f7;
    border-color: #52c0f7;
  }
}

.container-opacity {
  display: none;
}

.img-sidebar {
  transform: translate(0px) !important;
  transition: 0.5s all !important;
}
.img-sidebar-collapsed {
  transform: translate(-80px) !important;
  transition: 1s all !important;
}

.layout-sidebar-container {
  min-height: 100vh;
}

.logo-sidebar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  margin: 16px;
}

.logo-sidebar__img {
  width: 50px;
}

.whatsapp {
  position: fixed;
  width: 50px;
  height: 50px;
  bottom: 40px;
  right: 40px;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  z-index: 100;
}

.whatsapp-icon {
  margin-top: 10px;
}

@media (max-width: 768px) {
  .container-opacity {
    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 998;
    background-color: black;
    opacity: 0.6;
  }
  .ant-layout-sider {
    transform: translate(0px) !important;
    position: fixed !important;
    height: 100vh;
    z-index: 999;
    transition: 0.5s all !important;
  }
  .ant-layout-sider-collapsed {
    transform: translate(-80px) !important;
  }
}

.ant-menu-item {
  display: flex !important;
  align-items: center !important;
}
.ant-menu-submenu-title {
  display: flex !important;
  align-items: center !important;
}

.dropdown-toggle::after {
  display: block !important;
  margin-right: -38px !important;
  margin-top: 3px;
  color: #52c0f7;
}

.text-color-alkemy {
  color: #52c0f7;
}

.nav-link {
  padding: 0 !important;
}

.dropdown-menu {
  position: absolute;
  inset: 0px;
  margin: 0px;
  transform: translate(-110px, 66px) !important;
}

.menu-item-sidebar-hover {
  color: rgba(255, 255, 255, 0.65);
}

.menu-item-sidebar-hover:hover {
  color: rgba(255, 255, 255, 0.65);
}

.ant-menu-submenu > .ant-menu-submenu-title {
  color: rgba(255, 255, 255, 0.65);
}

.ant-menu-item-active .ant-menu-title-content {
  color: rgba(255, 255, 255, 0.65);
}

.home-section {
  min-height: calc(100vh - 5rem);
}

.not-accepted-tyc-container {
  height: 100vh;
  width: 100vw;
}

.modal-bg-image {
  height: 100%;
  background-image: url('../../assets/background/campus-home.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: blur(10px);
}
