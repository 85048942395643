.header {
  height: 5rem;
  background: #ffffff;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.1);
  padding: 0 1.625rem 0 1.75rem;
  top: 0 !important;
  z-index: 100;
}

.logo {
  height: 3.375rem;
  width: 7.5rem;
  object-fit: contain;
}

.dropdown-logo {
  width: 2rem;
  height: 2rem;
}

.dropdown-name {
  margin: auto 0.75rem;
  font-size: 0.875rem;
  line-height: 1rem;
  color: #182c4c;
}

.dropdown-menu-burger {
  width: 1.125rem;
  height: 0.875rem;
}