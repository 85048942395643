.country-flag[data-size="sm"] {
  width: 1rem;
  height: 0.75rem;
}

.country-flag[data-size="md"] {
  width: 1.5rem;
  height: 1.125rem;
}

.country-flag[data-size="lg"] {
  width: 2rem;
  height: 1.5rem;
}