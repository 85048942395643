.step1-hello {
  font-size: 1.5rem;
}

.step1-span {
  font-weight: 600;
  color: #828282;
}

.step1-age {
  font-size: 1.375rem;
}

.container-reason {
  background: #ffffff;
  box-shadow: 0px 1px 24px rgba(0, 0, 0, 0.1);
  border-radius: 80px;
}

.option-text {
  font-weight: 600;
  color: #828282;
  line-height: 22px;
  letter-spacing: 1px;
  margin-left: 3rem;
}

.countries-arrow {
  margin-right: 2.4rem;
}

.img-arrow-back {
  width: 0.625rem;
  transform: rotate(-90deg);
}

.icon-flag-img {
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 0.3rem;
}

.selectedOptions {
  background-color: #b0e2fb;
}

.unSelectedOptions {
  background-color: #ffffff;
}