.avatar-name {
  text-align: center;
  color: #fff;
  background: #b9b9b9;
}

.avatar-name-sm {
  width: 5rem;
  height: 5rem;
  font-size: 1.875rem;
}

.avatar-name-xs {
  width: 3.75rem;
  height: 3.75rem;
  font-size: 1.25rem;
}

.avatar-name-team-member {
  width: 3.375rem;
  height: 3.375rem;
  font-size: 1.25rem;
}

.avatar-name-lg {
  width: 9.375rem;
  height: 9.375rem;
  font-size: 3.125rem;
}

.avatar-name-md {
  width: 8.125rem;
  height: 8.125rem;
  font-size: 2.5rem;
}

.avatar-name-mentor {
  width: 6.25rem;
  height: 6.25rem;
  font-size: 1.875rem;
}

.avatar-name-mentor-s {
  width: 6.25rem;
  height: 6.25rem;
  font-size: 1.875rem;
}

@media screen and (min-width: 992px) {
  .avatar-name-mentor {
    width: 7.5rem;
    height: 7.5rem;
    font-size: 1.875rem;
  }
  .avatar-name-xs {
    width: 5rem;
    height: 5rem;
    font-size: 1.875rem;
  }
}
