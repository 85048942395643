.custom-modal-button {
  font-size: 0.875rem !important;
  line-height: 1rem !important;
  margin: 0rem 0.25rem !important;
  text-decoration-line: none !important;
  border-radius: 1rem !important;
  border: 0px !important;
}

#custom-modal-button-id,
#custom-modal-button-id:hover {
  background-color: #58c1f5 !important;
  padding: 0.5rem 1.25rem !important;
}

.custom-modal-button-complete > span,
.redmindme-later-button > span {
  font-family: 'Montserrat', 'Roboto', sans-serif !important;
  font-weight: 700 !important;
}

.reminder-modal-wrapper .ant-modal-content {
  border-radius: 1rem !important;
}
