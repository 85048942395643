.maintenance-title {
  font-size: 2.25rem;
  line-height: 2.375rem;
}

.maintenance-subtitle {
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2.375rem;
  color: #828282;
}

.maintenance-button {
  padding: 0.565rem 1rem;
  background: #58c1f5;
  border-radius: 1rem;
}

.maintenance-button-text {
  font-size: 0.875rem;
  line-height: 1rem;
  color: #ffffff;
}

.maintenance-button-secondary {
  padding: 0.565rem 1rem;
  background: #ffffff;
  border: 2px solid #58c1f5;
  border-radius: 1rem;
}

.maintenance-button-secondary-text {
  font-size: 0.875rem;
  line-height: 1rem;
  color: #58c1f5;
  margin: 0rem 0.5rem;
}
