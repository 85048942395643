.days-container {
  display: grid;
  width: 100%;
  max-width: 21.875rem;
  grid-template-columns: repeat(auto-fit, minmax(2.5rem, 1fr));
  gap: 0.625rem;
  button {
    border-radius: 14px;
    outline: none;
    padding: 0.625rem;
  }
}

@media screen and (min-width: 992px) {
  .days-container {
    width: 75%;
  }
}
