.empty-container {
  background-color: #f4f4f4;
  border-radius: 0.5rem;
}

.empty-subtitle {
  color: #828282;
}

.empty-subtitle-redirect {
  color: #3e5ba0;
  text-decoration: underline;
}
