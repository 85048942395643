.step {
  max-width: 4.125rem;
  height: 0.5rem;
  background-color: #eff0f4;
}

@for $percent from 0 to 11 {
  .step[data-percent='#{$percent}'] {
    background: linear-gradient(
      to right,
      #70d4bf $percent * 10%,
      #eff0f4 $percent * 10%,
      #eff0f4 100%
    );
  }
}

.first-step {
  border-radius: 50px 0px 0px 50px;
}

.last-step {
  border-radius: 0px 4px 4px 0px;
}
