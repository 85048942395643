.steps-children-container::-webkit-scrollbar {
  display: none;
}

.login-section-divider {
  padding: 2vh 0;
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 2.25rem;
  letter-spacing: 0.065rem;
  color: #828282;
}

.login-section-divider::before {
  content: '';
  margin-right: 1rem;
  top: 0;
  bottom: 0;
  width: 100px;
  height: 1px;
  border-bottom: 1px solid #c4c4c4;
  position: relative;
}

.login-section-divider::after {
  content: '';
  margin-left: 1rem;
  top: 0;
  bottom: 0;
  width: 100px;
  height: 1px;
  border-bottom: 1px solid #c4c4c4;
  position: relative;
}
