.modal {
  width: 100vw;
  height: 100vh;
  overflow-x: d-none;
  background-color: rgba(31, 32, 41, 0.5);
  pointer-events: none;
  opacity: 0;
  transition: opacity 250ms 700ms ease;
  z-index: 101 !important;
}

.visible {
  pointer-events: auto;
  opacity: 1;
  transition: all 300ms ease-in-out;
  overflow: d-none;
}

.non-visible {
  transition: all 300ms ease-out;
}

.modal__wrap {
  margin: auto 30rem;
  align-self: start;
  opacity: 0;
  transform: scale(0.6);
  transition: opacity 250ms 250ms ease;
  transform: scale(0);
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}

.modal__wrap.modal__iframe {
  max-width: 62.5rem;
}

.visible .modal__wrap {
  opacity: 1;
  transform: scale(1);
  transition: opacity 250ms 500ms ease;
}

.button-right-modal,
.button-left-modal {
  border-radius: 16px;
  box-sizing: border-box;
  border: 1px solid #58c1f5;
}
.button-left-modal {
  background: #ffffff;
}
.button-right-modal {
  background: #58c1f5;
  padding: 0.685rem 1.345rem;
}

.text-button-left-modal,
.text-button-right-modal {
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1rem;
  letter-spacing: 0.025rem;
  margin: 0prem 0.25rem;
}

.text-button-left-modal {
  color: #58c1f5;
}
.text-button-right-modal {
  color: #ffffff;
}

@media (min-width: 400px) {
  .modal__wrap {
    margin: auto 1rem;
  }
  .modal__wrap.modal__iframe {
    max-width: 62.5rem;
    margin: 0;
    .container-components-modal {
      margin: auto 0.5rem;
    }
  }

  .container-components-modal {
    margin: auto 1rem;
  }
}

@media (min-width: 100px) {
  .modal__wrap {
    margin: auto 0rem;
  }
  .modal__wrap.modal__iframe {
    margin: 0;
  }
}

@media (min-width: 700px) {
  .modal__wrap {
    margin: auto 6rem;
  }
  .modal__wrap.modal__iframe {
    margin: 0;
  }

  .container-components-modal {
    margin: auto 3.5rem;
  }
}

@media (min-width: 1000px) {
  .modal__wrap {
    margin: auto 14rem;
  }
  .modal__wrap.modal__iframe {
    margin: 2rem 7rem;
  }
}

@media (min-width: 1300px) {
  .modal__wrap {
    margin: auto 20rem;
  }
  .modal__wrap.modal__iframe {
    margin: 0;
  }
}

@media (min-width: 1500px) {
  .modal__wrap {
    margin: auto 26rem;
  }
  .modal__wrap.modal__iframe {
    margin: 0;
  }
}

@media (min-width: 1600px) {
  .modal__wrap {
    margin: auto 30rem;
  }
  .modal__wrap.modal__iframe {
    margin: 0;
  }
}

@media (min-width: 1800px) {
  .modal__wrap {
    margin: auto 38rem;
  }
  .modal__wrap.modal__iframe {
    margin: 0;
  }
}
