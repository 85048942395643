.unauthorizated-bg {
  background-color: #56ccf2;
  min-height: 100%;
}

.unauth-box {
  background-color: white;
  margin-bottom: 30px;
}

.unauth-text {
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
  line-height: 2rem;
}

.unauth-button {
  background-color: #ff5252;
}
