@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
}

.Caracteristicas-title {
  text-align: center !important;
  color: #404040;
  position: relative;
  z-index: 1;
}

.Caracteristicas-title-underline-green {
  position: relative;
}
.Caracteristicas-title-underline-green::after {
  background-color: #58d7a0;
  content: '';
  width: 100%;
  height: 30%;
  position: absolute;
  bottom: 4px;
  left: 0;
  z-index: -1;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-disable {
  pointer-events: none;
  cursor: no-drop;
}

.text-alkemy-black {
  color: #1a1a1a;
}

.text-underline {
  text-decoration: underline;
}

.font-roboto {
  font-family: 'Roboto';
  font-style: normal;
}

.text-pre-line {
  white-space: pre-line;
}

.font-tiny {
  font-size: 0.625rem;
}

.font-small {
  font-size: 0.875rem;
}

.font-smaller {
  font-size: 0.8rem;
}

.top-0 {
  top: 0 !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.left-0 {
  left: 0 !important;
}

.right-0 {
  right: 0 !important;
}

.mt-7 {
  margin-top: 8rem;
}

.custom-card {
  background: #ffffff;
  border-radius: 0.5rem;
  z-index: 40;
}

.line-height-1-5 {
  line-height: 1.5rem;
}

.bg-gray {
  background-color: #fafafa;
}

.bg-dark-green {
  background-color: #1bc47d;
}

.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #ffffff80 transparent;
}
.custom-scrollbar::-webkit-scrollbar {
  width: 0.5rem;
}
.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 20px;
  border: 4px solid #ffffff80;
}

.smooth-scroll {
  scroll-behavior: smooth;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.text-maya-blue {
  color: #58c1f5;
}

.border-maya-blue {
  border-color: #58c1f5;
}

.bg-maya-blue {
  background-color: #58c1f5;
}

.bg-light-gray {
  background-color: #f4f4f4;
}

.w-33,
.w-66 {
  width: 100%;
}

.font-rubik {
  font-family: 'Rubik';
}

@media (min-width: 768px) {
  .w-33 {
    width: 33%;
  }
  .w-66 {
    width: 66%;
  }
}

@media (min-width: 992px) {
  .pt-lg-8 {
    padding-top: 8.75rem !important;
  }
}

.bg-menu {
  background-color: #f4f4f4;
}

.profile-container {
  z-index: 999;
}

.unstyled-button {
  background: none;
  border: none;
}

button,
button:focus-visible {
  outline: none !important;
}

.ant-table-cell {
  font-family: 'Montserrat';
  font-style: normal;
}

.break-word {
  word-break: break-word;
}

.border-1 {
  border: 1px solid;
}

.text-center {
  text-align: center;
}
