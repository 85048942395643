.list-button {
  outline: none;
}
.link-list {
  width: 0px;
  transition: 0.3s;
}
.link-list.show-list {
  background-color: #011528;
  width: 13.5rem;
  transition: 0.3s;
  border-radius: 3px;
}
.link-list.collapsed-list {
  margin-left: 4rem;
}
.link_name.collapsed {
  width: 0px;
}
.link-list a {
  color: #c4c4c4;
  transition: 0.2s;
}
.list-item a:hover {
  color: white;
  transition: 0.2s;
}
.normal-arrow {
  transition: 0.3s;
}
.rotate-arrow {
  transform: rotate(-180deg);
  transition: 0.3s;
}
.link_name {
  color: #fff;
  font-weight: 500;
  transition: 0.5s;
}
