.ticket-sprint-text,
.ticket-technology-text {
  font-size: 0.813rem;
  line-height: 0.938rem;
  letter-spacing: 0.07em;
  padding: 0.375rem;
  border-radius: 0.125rem;
}

.profile-item-sprint {
  width: fit-content;
}

.ticket-sprint-text {
  color: white;
  background: #006404;
}

.ticket-rounded-text {
  font-size: 0.688rem;
  line-height: 0.813rem;
  letter-spacing: 0.07em;
  color: #403692;
  margin-right: 0.5rem;
  background: #eae7fe;
  border-radius: 1rem;
  padding: 0.25rem 0.5rem;
}

.ticket-light-orange {
  color: #fda741;
  background-color: #fff1e2;
}

.ticket-light-blue {
  color: #50b4ec;
  background-color: #e5f5fe;
}

.ticket-filter {
  background-color: #f4f4f4;
  color: #666666;
  font-size: 0.875rem;
}

.ticket-technology-text {
  color: #403692;
  background: #eae7fe;
}
