.assistance-title {
  font-size: 0.875rem;
  line-height: 1rem;
}

.assistance-content {
  font-size: 1.125rem;
  line-height: 1.315rem;
  letter-spacing: 0.07em;
  padding: 0.25rem 0.5rem;
  background: #5edca7;
  border-radius: 20px;
}
