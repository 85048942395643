.scroll-container-left {
  background: linear-gradient(90deg, #fff 30%, hsla(0, 0%, 100%, 0) 80%);
}

.scroll-container-right {
  background: linear-gradient(-90deg, #fff 30%, hsla(0, 0%, 100%, 0) 80%);
}

.scroll-arrow {
  z-index: 1;
}
