.step3-hello {
  font-size: 1.5rem;
}

.step3-span {
  font-weight: 600;
  color: #828282;
}

.step3-age {
  font-size: 1.375rem;
}

.container-country {
  box-shadow: 0px 1px 24px rgba(0, 0, 0, 0.1);
  border-radius: 80px;
}

.country-text {
  font-weight: 600;
  color: #828282;
  line-height: 22px;
  letter-spacing: 1px;
}

.countries-arrow {
  margin-right: 2.4rem;
}

.img-arrow-back {
  width: 0.625rem;
  transform: rotate(-90deg);
}

.country-flag-img {
  width: 1.25rem;
  height: 0.938rem;
}

.selectedOption {
  background-color: #b0e2fb;
}

.unselectedOption {
  background-color: #ffffff;
}