.dropdown-menu-text {
  font-size: 1rem;
  line-height: 3.875rem;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.dropdown-menu-responsive {
  width: 17rem;
  z-index: 9999999991;
  background-color: #011528;
  transform: translateX(100%);
  transition: transform 0.5s;
}

.dropdown-active {
  height: 100vh;
  transform: translateX(0);
  transition: transform 0.5s;
}

.menu-responsive-icon {
  width: 1.125rem;
  height: 1.125rem;
}
