.input-show-pass-btn {
  position: absolute;
  bottom: 0px;
  right: 0px;
  padding: 0.435rem 1.25rem;
  color: #444444;
  border: none;
  background: none;
  z-index: 1;
}

.input-show-pass-btn:focus-visible {
  outline: none;
}

.danger-text {
  color: #ee6a6a;
  font-size: 0.7rem;
}

.input-container {
  position: relative;
  padding-bottom: 0.125rem;
}

.input-container::before {
  content: '';
  display: inline-block;
  height: 1px;
  width: 100%;
  background: rgb(168, 168, 168);
  position: absolute;
  left: 0px;
  bottom: 0px;
  top: 19px;
}

.input-container-hover::before {
  height: 0.125rem;
}

.input-container::after {
  content: '';
  display: inline-block;
  height: 0.125rem;
  width: 100%;
  background: royalblue;
  position: absolute;
  left: 0rem;
  bottom: 0rem;
  transform: rotateY(90deg);
  transition: transform 0.3s;
  bottom: -1px;
}

.input-container-active::after {
  transform: rotateY(0deg);
}

.input-error::after {
  background: #ee6a6a;
  transform: rotateY(0deg);
  transition: transform 0.3s;
  top: 19px;
}

.custom-input {
  border: none;
  background: none;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;
  color: #828282;
}

.custom-input:focus-visible {
  outline: none;
}

.custom-input-label {
  transform: translateY(25px);
  transition: transform 0.3s, font-size 0.3s;
  cursor: text;
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 500;
  color: #828282;
  bottom: 25px;
}

.custom-input-label-active {
  transform: translateY(0);
  cursor: default;
  font-size: 0.8rem;
  line-height: 1rem;
  color: #272727;
}
