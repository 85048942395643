.switch {
  width: 2.75rem;
  height: 1.5rem;
  background-color: #58C1F5;
  border-radius: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.unselected-option-black {
  color: rgba(0, 0, 0, 0.4);
}

.slider {
  height: 1rem;
  width: 1rem;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.slider::after {
  position: absolute;
  content: '';
  height: 1rem;
  width: 1rem;
  left: 1.375rem;
  bottom: -4px;
  border-radius: 50%;
  background-color: #FFFFFF;
  transition: .3s;
}

input:checked + .slider::after {
  transform: translateX(-1.065rem);
  transition: .3s;
}