.description-container {
  max-width: 71rem;
}

.banner-icon-size {
  height: 3.75rem;
}

.back-button-border {
  border: 1px solid;
}
