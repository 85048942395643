.step1-text {
  font-size: 1rem;
}

.step1-span {
  font-weight: 600;
  color: #828282;
}

.steps-children-container {
  max-height: 100vh;
  overflow-y: scroll;
}

.form-step-container {
  max-height: 100vh;
  overflow-y: auto;
}

.step-logo-alkemy {
  width: 7.625rem;
  height: 1.435rem;
}
