.dropdown-select-container {
  font-size: 0.875rem;
  line-height: 1rem;
  color: #182c4c;
  background-color: #fff;
}

.dropdown-select-button {
  width: max-content;
}

.dropdown-select-arrow {
  transition: transform 0.3s;
}

.dropdown-select-arrow-open {
  transform: rotate(-180deg);
}

.dropdown-select-options-container {
  list-style: none;
  z-index: 1;
  max-height: 0;
}

.dropdown-select-options-container-open {
  max-height: 12.5rem;
}

.dropdown-container-width {
  width: 9.375rem;
}

.dropdown-selec-option:hover {
  background-color: #fafafa;
}

.header-dropdown-max-height {
  max-height: 3rem;
}

.dropdown-text {
  font-size: 0.875rem;
  line-height: 3rem;
  color: #182c4c;
}

.dropdown-link:hover {
  text-decoration: none;
}

.dropdown-link:active {
  outline: none;
  border: none;
}

.dropdown-option-img {
  width: 1.25rem;
  height: 1.25rem;
}
