.description-container {
  max-width: 65rem;
}

.date-font {
  font-size: 1.25rem;
}

.border-lg-right {
  border-right: none;
}

.about-title {
  font-size: 1.125rem;
}

.about-container {
  max-width: none;
  background-color: #f4f4f4;
}

.job-ready-button {
  color: #58c1f5;
  border-radius: 16px;
  border: 1px #58c1f5 solid;
}

.job-ready-button:hover {
  color: #58c1f5;
  text-decoration: none;
}

.calendar-link {
  background-color: #58c1f5;
  border-radius: 16px;
}

.useful-content-link {
  background-color: #58c1f5;
  border-radius: 22px;
}

.group-container {
  max-width: none;
  min-width: 21rem;
}

.next-events-image {
  width: 8.125rem;
  height: 11.75rem;
  object-fit: cover;
  border-radius: 8px;
}

.useful-content-video {
  width: 8.75rem;
  height: 5rem;
  object-fit: cover;
}

.next-event-name {
  font-size: 1rem;
}
.useful-content {
  max-width: none;
}

.linkedin-text,
.linkedin-text:hover {
  font-size: 0.625rem;
  color: black;
}

.linkedin-image {
  width: 1.25rem;
  height: 1.25rem;
}

@media screen and (min-width: 992px) {
  .border-lg-right {
    border-right: 1px solid #dee2e6;
  }
  .about-container {
    max-width: 18rem;
  }
  .group-container {
    max-width: 21rem;
  }
  .next-events-image {
    width: 12.375rem;
    height: 14.5rem;
  }
  .next-event-name {
    font-size: 2.25rem;
  }
  .linkedin-text,
  .linkedin-text:hover {
    font-size: 0.875rem;
  }
  .linkedin-image {
    width: 1.5rem;
    height: 1.5rem;
  }
  .useful-content {
    max-width: 42.875rem;
  }
}
