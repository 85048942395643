.video-modal-container {
  background-color: #222222;
  z-index: 9990;
}

.video-modal-close-button {
  color: #58c1f5;
  border: 1px solid #58c1f5;
  background: none;
  border-radius: 20px;
}

.video-modal-title {
  font-size: 1.125rem;
}

.video-modal-player-wrapper {
  position: relative;
  padding-top: 56.25%;
}

.video-modal-max-width {
  max-width: 50rem;
  width: 94%;
}

.video-modal-open {
  transform: translateY(0);
  transition: transform 0s, opacity .3s;
  opacity: 1;
}

.video-modal-closed {
  transform: translateY(100%);
  transition: opacity .3s, transform 0s .3s;
  opacity: 0;
}

@media (min-width: 576px) {
  .video-modal-max-width {
    width: 90%;
  }
}
