.feedback-name {
  font-size: 0.875rem;
  line-height: 1rem;
  letter-spacing: 0.025rem;
  color: #182c4c;
}

.feedback-ticket {
  padding: 0.175rem 0.315rem;
  width: 2.375rem;
  height: 1.065rem;
  background: #1bc47d;
  border-radius: 2px;
  font-size: 0.565rem;
  line-height: 0.685rem;
  color: #ffffff;
}

.feedback-content {
  font-weight: 600;
  font-size: 0.875rem;
}

.feedback-img {
  width: 2.125rem;
  height: 2rem;
}
