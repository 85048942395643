.certificate-container {
  max-width: none;
}

.certificate-image {
  object-fit: cover;
  max-width: 100%;
  box-shadow: 0 0.875rem 1.75rem rgba(0,0,0,0.25), 0 0.625rem 0.625rem rgba(0,0,0,0.22);
}

.empty-container-size {
  min-height: 15rem;
  max-height: none;
}

.border-empty-certificate {
  border: 4px dashed white;
}

.react-pdf__Page__textContent {
  display: none;
}

.muscle-icon {
  font-size: 2rem;
}

.good-way {
  font-size: 1rem;
}

.add-certification-button {
  border: 1px solid #58c1f5;
}

@media screen and (min-width: 992px) {
  .certificate-container {
    max-width: 40rem;
  }
  .empty-container-size {
    max-height: 28.125rem;
  }
  .border-empty-certificate {
    border: 8px dashed white;
  }
  .good-way {
    font-size: 1.375rem;
  }
  .muscle-icon {
    font-size: 3.375rem;
  }
}
