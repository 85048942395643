.width-card-mentor {
  width: 30%;
}

.width-variable {
  width: 70%;
}

.width-know-card-50 {
  width: 50%;
}
.width-know-card-100 {
  width: 100%;
}

.font-size-small {
  font-size: 0.815rem;
}

.mentor-select-option .css-c7bkub-control div .css-xb97g8 {
  display: none;
}

.mentor-select-option .css-1rhbuit-multiValue {
  height: 16px;
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: .5rem 0; 
}

@media (max-width: 991px) {
  .width-variable,
  .width-card-mentor,
  .width-card-know,
  .width-know-card-50,
  .width-know-card-100 {
    width: 100%;
  }
}
