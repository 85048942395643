.progress-container {
  z-index: 99;
}

.progress-training {
  background: linear-gradient(89.98deg, #59c2f3 0%, #9bf55a 99.98%);
  z-index: 99;
  border-radius: 10rem;
  max-width: 100%;
}

.progress-training-bg {
  z-index: -1;
  background: #ebebeb;
  top: 0%;
  left: 0%;
  border-radius: 10rem;
}
