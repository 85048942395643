.title-technology {
  font-size: 0.875rem;
  line-height: 1.065rem;
  color: #828282;
}

.title-cohort {
  font-size: 0.875rem;
  line-height: 1.065rem;
  color: #828282;
}

.text-cohort {
  font-size: 0.875rem;
  line-height: 1.065rem;
}

.button-unsuscribe {
  background: #ffffff;
  border: 1px solid #58c1f5;
  box-sizing: border-box;
  border-radius: 16px;
}

.button-unsuscribe-text {
  font-size: 0.875rem;
  line-height: 1rem;
  color: #58c1f5;
}

.input-challenge-container {
  background: #f4f4f4;
  border-radius: 8px;
}

.span-challenge-text {
  line-height: 1.065rem;
  font-size: 1rem;
  color: #58c1f5;
}
.span-link-typeform,
.span-link-typeform:hover,
.span-link-typeform:focus {
  color: #58c1f5;
  text-decoration: underline;
}

.input-challenge-text {
  color: gray;
  line-height: 1.065rem;
  font-size: 1rem;
}

.pdf-container {
  background: #f4f4f4;
  border-radius: 8px;
}

.pdf-title {
  font-size: 1.125rem;
  line-height: 1.375rem;
}

.ticket-review-text {
  color: #176b71;
  background: #d0f0ff;
}

.ticket-not-send-text {
  color: black;
  background: #ffd018;
}

@media (max-width: 992px) {
  .button-uns {
    position: absolute;
    bottom: -50px;
  }
}
