.welcome-subtitle {
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.065rem;
  color: #828282;
}
.available-subtitle {
  font-size: 0.875rem;
  line-height: 1.065rem;
  color: #828282;
}

.welcome-button {
  background: #58c1f5 !important;
  border-radius: 16px;
  padding: 0.565rem 1rem;
}

.welcome-button-text,
.welcome-button-text:hover {
  font-size: 0.875rem;
  line-height: 1rem;
  margin: 0rem 0.5rem;
  color: #ffffff !important;
  text-decoration: none !important;
}
