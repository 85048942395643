.dropdown-lang-select-container {
  font-size: 0.874rem;
  line-height: 1rem;
  color: #828282;
  background-color: #fff;
  z-index: 1;
}

.dropdown-lang-select-arrow {
  transition: transform 0.3s;
}

.dropdown-lang-select-arrow-open {
  transform: rotate(-180deg);
}

.dropdown-lang-select-options-container {
  list-style: none;
  max-height: 0;
  min-width: 100%;
}

.dropdown-lang-select-options-container-open {
  max-height: 12.5rem;
}

.self-end {
  white-space: nowrap;
}

.dropdown-lang-select-option {
  min-width: 100;
}
.dropdown-lang-select-option:hover {
  background-color: #fafafa;
}

.dropdown-lang-selected-option {
  color: #505050;
  background-color: #ebebeb;
}

.dropdown-lang-select-placeholder {
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 1.125rem;
}

.dropdown-lang-max-height {
  max-height: 3.125rem;
}

.dropdown-lang-container-index {
  z-index: 3;
}

.dropdown-lang-gray-bg {
  background-color: #f4f4f4;
}
