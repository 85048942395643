.report-pre-title {
  font-style: normal;
  font-size: 0.875rem;
  line-height: 1.125rem;
}

.report-pre-skill {
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 2rem;
  color: #4f4f4f;
}

.report-pre-skill-number {
  padding: 0.25rem 0.5rem;
  background: #ffdc57;
  border-radius: 20px;
  font-size: 0.75rem;
  line-height: 0.875rem;
}
