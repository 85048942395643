.ant-steps-item-icon .ant-steps-icon {
  line-height: 1.6;
}

.ant-steps-item-icon .ant-steps-finish-icon {
  margin-top: 0.4rem;
}

.anticon-warning > svg {
  fill: red;
}
