.certificate-input {
  border: 1px #c9c9c9 solid;
  outline: none;
}

.certificate-input::placeholder {
  font-family: 'Montserrat';
  color: #c9c9c9;
}

.select-level-english{
  width: 33%;
}
@media (max-width: 992px) {
  .select-level-english{
    width: 100%;
  }
}