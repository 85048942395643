.selected-technology {
  @apply flex items-center rounded-lg px-2 mr-2 my-1;
  @apply bg-white dark:bg-dark-normal dark:text-white;
  border: 1px solid #eff0f4;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
}

.css-ll629-menu {
  @apply dark:bg-dark-normal;
}

.css-b8ldur-Input {
  @apply dark:text-white;
}

.css-1rgevp3-option {
  @apply dark:bg-dark-bold;
}
