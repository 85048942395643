.accordion__icon {
  transition: transform 0.6s ease;
  transform: rotate(270deg);
}

.rotate {
  transform: rotate(360deg);
}

.accordion__content {
  transition: max-height 0.6s ease;
  z-index: 7;
  max-height: 0;
}

.accordion__text {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
}

.accordion-open {
  max-height: 100%;
}

.accordion__section {
  transition: border-color 0.2s ease-in-out;
  transition: border 0.2s ease-in-out;
}
