.name-text text-alkemy-black {
  font-size: 1.75rem;
  line-height: 2.125rem;
}

.technology-text {
  color: #4f4f4f;
}

.technology-text::before {
  content: ' ';
}

.age-text {
  margin-top: 0.125rem;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;
  color: #787878;
}
