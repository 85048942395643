.table-progress {
  width: 80%;
}

.progress-dropdown {
  width: min-content;
  top: 1rem;
  min-height: 1rem;
}

.progress-dropdown-position {
  position: absolute;
}

.progress-skill-title {
  font-size: 0.815rem;
  line-height: 1rem;
}

.technical-skills-card-title {
  font-size: 1.125rem;
  line-height: 1rem;
}

.technical-skills-card-subtitle {
  font-size: 1.125rem;
  line-height: 1rem;
  color: #4f4f4f;
}

.progress-skill-counter {
  font-size: 0.815rem;
  line-height: 1rem;
  color: #828282;
}

.progress-outstanding {
  font-size: 0.565rem;
  letter-spacing: 0.15em;
  color: #4f4f4f;
  z-index: 999;
  max-height: 1rem;
}

.dropdown-button {
  background-color: transparent;
  border: none;
  font-weight: normal;
  font-size: 0.685rem;
  line-height: 0.815rem;
  color: #5f5f5f;
}

.dropdown-button:focus-visible {
  outline: none;
}

.panel-group {
  margin-top: 1.875rem;
}

.item-collapse {
  z-index: 999;
}

.skill-completed,
.skill-incompleted {
  padding: 4px 8px;
  border-radius: 16px;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.07em;
}

.skill-incompleted {
  background: #eff0f4;
  color: #828282;
}

.skill-completed {
  background: #e4fcef;
  color: #0a6645;
}

@for $percent from 0 to 100 {
  .skill-custom-advance-background[data-percent='#{$percent}'] {
    background: linear-gradient(to right, #e4fcef $percent * 1%, #eff0f4 $percent * 1%, #eff0f4 100%);
  }
}

@for $percent from 0 to 100 {
  .skill-custom-advance[data-percent='#{$percent}'] {
    background: #0a6645;
    background: -webkit-linear-gradient(to right, #0a6645 $percent * 1%, #828282 $percent * 1%, #828282 100%);
    background: -moz-linear-gradient(to right, #0a6645 $percent * 1%, #828282 $percent * 1%, #828282 100%);
    background: linear-gradient(to right, #0a6645 $percent * 1%, #828282 $percent * 1%, #828282 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.ticket-training-job-ready {
  font-size: 0.685rem;
  line-height: 0.815rem;
  letter-spacing: 0.07em;
  color: #0a6645;
  padding: 0.25rem 0.5rem;
  background: #e4fcef;
  border-radius: 1rem;
  margin-left: 0.565rem;
}

.content {
  padding: 0 1.125rem;
  background-color: white;
  max-height: 0;
  overflow: d-none;
  transition: max-height 0.2s ease-out;
}

.dropdown-arrow {
  right: 0;
  width: 0.625rem;
  height: 0.375rem;
  transition: transform 0.5s;
}

.dropdown-arrow-open {
  transform: rotate(-180deg);
}

@media (min-width: 576px) {
  .progress-dropdown {
    margin-left: 0.3rem;
    margin-top: 0.8rem;
    min-height: 0;
    top: 0;
  }

  .progress-dropdown-position {
    position: relative;
  }
}
