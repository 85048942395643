* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.sidebar {
  left: -12.5rem;
  height: calc(100% - 5rem);
  width: 12.5rem;
  background-color: #011528;
  transition: all 0.5s;
  z-index: 100;
}

.sidebar .nav-links {
  padding-top: 0.875rem;
  max-height: calc(100% - 5.5rem);
}

.sidebar .nav-links li {
  list-style: none;
}
.sidebar .nav-links li:nth-child(1) {
  list-style: none;
  margin: 0;
}

.sidebar-icon-rotate {
  transform: rotate(-90deg);
  transition: transform 0.5s;
}

.sidebar-icon {
  width: 1.5rem;
  height: 1.5rem;
}

.sidebar .nav-links li img {
  margin-left: 1.1rem;
}

.sidebar .nav-links li a {
  text-decoration: none;
  white-space: nowrap;
}

.nav-links li a:hover {
  text-decoration: none;
  color: #fff;
}

.sidebar .nav-links li a span {
  color: #fff;
  font-weight: 500;
  font-size: 1rem;
  margin-left: 1.375rem;
  transition: 0.5s;
}

.sidebar-active::after {
  content: '';
  background-color: #58c1f5;
  position: absolute;
  left: 0;
  top: 0;
  width: 0.188rem;
  height: 2.5rem;
}

.sidebar.close-sidebar {
  width: 4rem;
}

.sidebar.close-sidebar .sidebar-icon-rotate {
  transform: rotate(0deg);
  transition: transform 0.5s;
}

.sidebar.close-sidebar .nav-links li .nav-links__item .link_name {
  color: rgba(255, 255, 255, 0.2);
  transition: 0.5s;
}

.sidebar.close-sidebar .nav-links li a {
  margin-left: 0.015rem;
  white-space: nowrap;
}

.sidebar.close-sidebar .menu-collapses {
  padding: 0 auto;
}

.home-section {
  position: relative;
  left: 0;
  transition: all 0.5s;
}

.home-content {
  width: 100%;
  height: inherit;
}

.sidebar.close-sidebar ~ .home-section {
  left: 0;
  transition: all 0.5s;
}

.home-section .home-content .bx-menu,
.home-section .home-content .text {
  color: #11101d;
  font-size: 2.188rem;
}

.home-section .home-content .bx-menu {
  margin: 0 0.938rem;
  cursor: pointer;
}

.home-section .home-content .text {
  font-weight: 600;
}

@media (max-width: 768px) {
  .sidebar {
    display: none;
  }
  .sidebar.close-sidebar {
    width: 0;
  }
  .sidebar.close-sidebar ~ .home-section {
    width: 100%;
    left: 0;
  }
  .home-section {
    left: 0;
    width: 100%;
  }
}
@media (min-width: 1024px) {
  .sidebar {
    display: block;
    left: 0;
  }
  .home-section.with-sidebar {
    left: 12.5rem;
    width: calc(100% - 12.5rem);
  }
  .sidebar.close-sidebar ~ .home-section {
    left: 4rem;
    width: calc(100% - 4rem);
  }
}
