.multiplatform-bg {
  background-color: #e6e6e6;
  min-height: 100%;
}

.multiplatform-box {
  background-color: #fff;
  width: 95%;
  max-width: 30rem;
}

.multiplatform-logo {
  height: 3.375rem;
  width: 7.5rem;
  object-fit: contain;
}

.multiplatform-subtitle {
  color: #5c5c5c;
}
