.user-heading {
  background: #ffffff;
  border-radius: 8px;
}

.profile-menu {
  max-width: auto;
}

.user-heading-img {
  width: 130px;
  height: 130px;
}

.hover-overlay {
  border-left: 5px solid #f8f7f5;
}

.hover-overlay:hover {
  background: #f8f7f5;
  border-left: 5px solid #52c0f7;
}

.selected {
  background: #f8f7f5;
  border-left: 5px solid #52c0f7;
}

.email-card-width {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.25px;
  color: #787878;
}

.card-button-edit {
  display: flex;
  flex-direction: flex flex-row;
  justify-content: center;
  align-items: center;
  padding: 8px 8px 8px 8px;
  background: #ffffff;
  border: 1px solid #c9c9c9;
  box-sizing: border-box;
  border-radius: 4px;
}

.card-button-text {
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.065rem;
  color: #828282;
  margin: 0rem 0.25rem;
}

.button-edit-image {
  margin-left: 10px;
  background-color: transparent;
  border: none;
}

.button-edit-image-profile {
  right: 1.25rem;
  bottom: 0;
}

.icon-edit-image {
  font-size: 1.5rem;
}

.video-upload-text {
  font-size: 0.875rem;
  line-height: 1.065rem;
}

.bubble {
  background: #eff0f4;
  border-radius: 0.935rem;
  width: 3.5rem;
  height: 1.875rem;
}

.profile-menu-container {
  padding: 3rem;
}

.player-size-profile.skeleton-heigth {
  height: 14rem;
}

.player-size-profile {
  width: 20rem !important;
  max-height: 15rem;
}

.ant-upload.ant-upload-select-picture-card,
.ant-upload-list-picture-card-container {
  width: 150px;
  height: 150px;
}

.ant-input-affix-wrapper,
.ant-input {
  background-color: transparent;
}

.disabled-account-text {
  color: #ffd018;
  font-size: 0.625rem;
}

@media (max-width: 600px) {
  .player-size-profile {
    width: 100% !important;
  }
}

@media (min-width: 768px) {
  .profile-menu {
    max-width: 20rem;
  }
}

.margin-button-certificate {
  margin-left: 5rem;
}

.reminder-light-orange {
  background-color: #fffb007d;
}
