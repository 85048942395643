.pdf-container-view {
  width: 50%;
  right: 25%;
  margin: 0 auto !important;
  position: absolute !important;
  display: flex;
  justify-content: center;
  z-index: 1;
}

@media (max-width: 700px) {
  .pdf-container-view {
    width: 100%;
    right: auto;
    margin: 0 auto !important;
    position: relative !important;
  }
}
