.flex-1 {
    flex: 1;
}

.column-gap {
    column-gap: 0.1rem;
}

.align-items-center {
    align-items: center;
}


.button {
    background-color: #272727;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    padding: 0.4rem 0rem;
}

.border-first {
    border-radius: 0 0 0 0.5rem;
}

.border-last {
    border-radius: 0 0 0.5rem 0;
}

.tooltip-home .ant-tooltip-inner {
  text-align: center !important;
}