.modal_custom {
  width: 100vw;
  height: 100vh;
  overflow-x: d-none;
  background-color: rgba(31, 32, 41, 0.5);
  pointer-events: none;
  opacity: 0;
  transition: opacity 250ms 700ms ease;
  z-index: 101 !important;
}

.visible {
  pointer-events: auto;
  opacity: 1;
  transition: all 300ms ease-in-out;
  overflow: d-none;
}

.modal__wrap_custom {
  align-self: start;
  opacity: 0;
  transform: scale(0.6);
  transition: opacity 250ms 250ms ease, transform 300ms 250ms ease;
  transform: scale(0);
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  max-width: 32rem;
  width: 100%;
}

.visible .modal__wrap_custom {
  opacity: 1;
  transform: scale(1);
  transition: opacity 250ms 500ms ease, transform 350ms 500ms ease;
}

.button-right-modal,
.button-left-modal {
  border-radius: 16px;
  box-sizing: border-box;
  border: 1px solid #58c1f5;
}
.button-left-modal {
  background: #ffffff;
}
.button-right-modal {
  background: #58c1f5;
  padding: 0.685rem 1.345rem;
}

.text-button-left-modal,
.text-button-right-modal {
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1rem;
  letter-spacing: 0.025rem;
  margin: 0prem 0.25rem;
}

.text-button-left-modal {
  color: #58c1f5;
}
.text-button-right-modal {
  color: #ffffff;
}

.button-disabled-account {
  background: #f0bf00;
  border-radius: 1rem;
}

.button-disabled-account:disabled {
  background: rgba(75, 75, 75, 0.75);
  border-radius: 1rem;
}
