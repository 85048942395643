.hide-table-content {
  display: block;
  @media screen and (max-width: 843px) {
    display: none !important;
  }
}

@media screen and (max-width: 1150px) {
  .view-more-button-text {
    display: none;
  }

  .view-more-button {
    width: fit-content !important;
  }
}

.hide-certificate-button {
  @media screen and (max-width: 843px) {
    display: none !important;
  }
}
